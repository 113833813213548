import {Link, NavLink} from "react-router-dom";
// import BookIcon from "../icons/BookIcon";
import HomeIcon from "../icons/HomeIcon";

const Sidebar = () => {
    return (
        <aside className="bg-purple-50 text-black w-72 fixed h-full z-20 border-r border-gray-200">
            <div className="aside-container">
                <div className="aside-content h-full px-3 py-4 bg-purple-50">
                    <a href='/' className="flex items-center ps-2.5 mb-5">
                        <img src='/assets/images/logo.png' className="h-6 me-3 sm:h-7" alt="VocsApp Logo" />
                    </a>
                    <ul className="flex flex-col space-y-1.5 mt-10">
                        <NavLink to='/'
                                 className={({ isActive }) =>
                                     isActive
                                         ? 'py-2 px-4 rounded-lg bg-blue-700 items-center text-white cursor-pointer flex'
                                         // : 'py-2 px-4 rounded-lg bg-white hover:bg-blue-700 hover:text-white items-center cursor-pointer flex'
                                         : 'py-2 px-4 rounded-lg bg-blue-700 items-center text-white cursor-pointer flex'
                                 }>
                            <HomeIcon className="h-5 w-5" />
                            <span className="ms-3">Home</span>
                        </NavLink>
                        <NavLink to='/books'   onClick={(e) => e.preventDefault()} className='py-2 px-4 text-gray-500 cursor-not-allowed rounded-lg bg-gray-50 items-center flex disabled:opacity-50'>
                            <span className="ms-3">Books (coming soon)</span>
                        </NavLink>
                        <NavLink to='/settings'   onClick={(e) => e.preventDefault()} className='py-2 px-4 text-gray-500 cursor-not-allowed rounded-lg bg-gray-50 items-center flex disabled:opacity-50'>
                            <span className="ms-3">Settings (coming soon)</span>
                        </NavLink>
                    </ul>
                </div>

                <div className="m-2 mb-8 text-center justify-start bg-white border border-gray-200 rounded-lg shadow sm:p-3 mt-auto">
                    <h5 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">Download App</h5>
                    <p className="mb-3 text-base text-gray-500 sm:text-sm dark:text-gray-400">Proqramı yükləmək üçün QR kodu skan edin və yaxud linkə keçid edin.</p>

                    <img src="/assets/images/qr_code.png" alt="QR Code" className="w-40 h-36 mx-auto mb-2" />

                    <div className="flex items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-2 rtl:space-x-reverse">
                        <Link to="https://apps.apple.com/az/app/vocsapp/id6445995880"
                              target={"_blank"}
                              className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-1.5">
                            <svg className="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab"
                                 data-icon="apple" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path fill="currentColor"
                                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
                            </svg>
                            <div className="text-left rtl:text-right">
                                <div className="-mt-1 font-sans text-sm font-semibold">App Store</div>
                            </div>
                        </Link>
                        <Link to="https://play.google.com/store/apps/details?id=az.vocabulary.az&hl=en_US"
                              target={"_blank"}
                              className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-1.5">
                            <svg className="me-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab"
                                 data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512">
                                <path fill="currentColor"
                                      d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path>
                            </svg>
                            <div className="text-left rtl:text-right">
                                <div className="-mt-1 font-sans text-sm font-semibold">Google Play</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
