import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from "axios";

const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkUser = async () => {
            setLoading(true)
            const token = localStorage.getItem('vocsAppAccessToken');

            if (token) {
                try {
                    const response = await fetch('https://api.vocabulary.az/api/auth/me', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    const data = await response.json();

                    if (response.ok) {
                        setUser(data.data);
                        setIsAuthenticated(true);
                        setLoading(false);
                    } else {
                        localStorage.removeItem('vocsAppAccessToken');
                    }
                } catch (error) {

                    console.error('Error verifying token:', error);
                    localStorage.removeItem('vocsAppAccessToken');
                }

            }
            setIsAuthenticated(false);
            setLoading(false);
        };
        checkUser();
    }, [isAuthenticated]);

    const login = (newUser) => {
        localStorage.setItem('vocsAppAccessToken', newUser.token);
        setUser(newUser);
    };

    const logout = async () => {
        try {
            await axios.post('https://api.vocabulary.az/api/auth/logout', {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('vocsAppAccessToken')}`,
                },
            });
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            localStorage.removeItem('vocsAppAccessToken');
            localStorage.removeItem('refreshToken');
            setUser(null);
            setIsAuthenticated(false);
            window.location.href = '/';
        }
    };

    const fetchUserInfo = async (token) => {
        try {
            const response = await axios.post('/api/userinfo', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(response.data)
            setUser(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                await refreshToken();
            } else {
                console.error('User info fetch error:', error);
            }
        }
    };

    const refreshToken = async () => {
        try {
            const response = await axios.post('/api/refresh-token', {
                token: localStorage.getItem('refreshToken'),
            });
            localStorage.setItem('jwt', response.data.jwt);
            await fetchUserInfo(response.data.jwt);
        } catch (error) {
            console.error('Token refresh error:', error);
            // await logout();
        }
    };

    return (
        <AuthContext.Provider value={{user, login, logout, fetchUserInfo, isAuthenticated, setIsAuthenticated, loading}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext

export const useAuth = () => {
    return useContext(AuthContext);
};
