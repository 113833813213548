import './App.css';
import Sidebar from "./components/Sidebar";
import BookList from "./pages/books/BooksList";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import React, {useEffect} from "react";
import UnitWordsList from "./pages/unitWords/UnitWordsList";
import BookInfo from "./pages/bookUnits/BookInfo";
// import LoadingSpinner from "./components/LoadingSpinner";
// import AuthContext from "./components/context/AuthContext";
import HomePage from "./pages/home/homePage";
import UAParser from 'ua-parser-js';

function App() {
    // const navigate = useNavigate();

    // const {isAuthenticated } = useContext(AuthContext);
    //
    // if (isAuthenticated === null) {
    //     return <LoadingSpinner />
    // }
    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        const isMobile = result.device.type === 'mobile';
        const isIphone = result.os.name === 'iOS';

        if (isMobile) {
            if (isIphone) {
                window.location.href = 'https://apps.apple.com/az/app/vocsapp/id6445995880';
            } else {
                window.location.href = 'https://play.google.com/store/apps/details?id=az.vocabulary.az&hl=en_US';
            }
        }
    }, []);

    return (
        <Router>
            <div className="flex">
                <Sidebar/>
                <div className="flex-1 ml-72">
                    {/*<Header/>*/}
                    <main className="flex-1 p-5 mt-2">

                        <Routes>
                            <Route path="/" element={<HomePage/>}/>

                            <Route path="/books" element={<BookList/>}/>
                            <Route path="/book" element={<BookInfo/>}/>

                            <Route path="/book-units/:uniqueId" element={<BookInfo/>}/>
                            <Route path="/book-unit-words/:bookId/:unitId" element={<UnitWordsList/>}/>

                        </Routes>
                    </main>
                </div>
            </div>
        </Router>
    );
}

export default App;
