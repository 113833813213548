import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import {Link, useParams} from "react-router-dom";
import apiConfig from '../../config/config';


const BookUnitList = ({book}) => {
    const {uniqueId} = useParams();

    const [bookUnits, setBookUnits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        const fetchBookUnits = async () => {
            try {
                const response = await fetch(`${apiConfig.golangApi}/book-units/` + uniqueId, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                const rows = data || [];

                if (!Array.isArray(rows)) {
                    throw new Error('Fetched data is not an array');
                }

                setBookUnits(rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBookUnits();
    }, [uniqueId]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredBookUnits = bookUnits.filter((book) =>
        book.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return <main className="flex-1 p-4">
            <LoadingSpinner/>
        </main>;
    }
    return (
        <div className="relative overflow-x-auto mt-9 rounded-lg">
            <div className="flex justify-end space-y-4 md:space-y-0 pb-4 bg-white">

                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative">
                    <div
                        className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="text" id="table-search-users"
                           value={searchTerm}
                           onChange={handleSearchChange}
                           className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                           placeholder="Search"/>
                </div>
            </div>

            <table className="w-full text-sm text-left rtl:text-right">
                <thead className="text-xs text-black uppercase bg-purple-50">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Unit Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Words
                    </th>
                    {/*<th scope="col" className="px-6 py-3">*/}
                    {/*    Add*/}
                    {/*</th>*/}
                    <th scope="col" className="px-6 py-3">
                        Test
                    </th>
                </tr>
                </thead>
                <tbody>
                {filteredBookUnits.map((bookUnit) => (
                    <tr className="bg-white hover:bg-purple-50">
                        <th scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                            {bookUnit.title}
                        </th>
                        <td className="px-6 py-4">
                            {bookUnit.word_count}
                        </td>
                        {/*<td className="px-6 py-4">*/}
                        {/*    Accessories*/}
                        {/*</td>*/}
                        <td className="px-6 py-4">

                            {book.free === 1 && (
                                <Link to={`/book-unit-words/${uniqueId}/${bookUnit.id}`}
                                      className="text-blue-500 hover:underline">
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                        Words
                                    </button>
                                </Link>
                            )}
                            {book.free === 0 && (
                                <button
                                    type="button"
                                    data-modal-target="popup-modal" data-modal-toggle="popup-modal"
                                    className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded-sm">
                                    Words
                                </button>
                            )}

                        </td>
                    </tr>

                ))}

                </tbody>
            </table>


        </div>


    )
}

export default BookUnitList