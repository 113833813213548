import { TailSpin } from 'react-loader-spinner';

const LoadingSpinner = () => {
    return (
        <div className="flex justify-center items-center min-h-screen">
            <TailSpin
                height="80"
                width="80"
                color="#0000FF"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
};

export default LoadingSpinner;
