import React from "react";
import {useEffect, useState} from "react";
import BookItem from "./BookItem";
import LoadingSpinner from "../../components/LoadingSpinner";
import apiConfig from '../../config/config';


const BookList = () => {
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const response = await fetch(`${apiConfig.golangApi}/books`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                const booksArray = data.data || [];

                if (!Array.isArray(booksArray)) {
                    throw new Error('Fetched data is not an array');
                }

                setBooks(booksArray);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBooks();
    }, []);

    if (loading) {
        return <main className="flex-1 p-4">
            <LoadingSpinner />
        </main>;
    }


    return (

        <div className="p-3">
            <h2 className="text-2xl font-bold mb-4">Books</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {books.map((book) => (
                        <BookItem id={book.ID}
                                  uniqueId={book.uniqueId}
                                  title={book.title}
                                  image={book.image}
                                  author={book.author}
                                  price={book.price}
                        />
                    ))}
            </div>
        </div>
    )
}

export default BookList