import BookUnitList from "./BookUnitList";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import apiConfig from '../../config/config';


const BookInfo = () => {

    const {uniqueId} = useParams();
    const [book, setBook] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiConfig.golangApi}/books/` + uniqueId, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                setBook(data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData().then(r => console.log(r));
    }, [uniqueId]);

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold">{book.title}</h1>

            <div className="mt-6 flex">
                <img src={book.image}
                     className="w-64, h-52 rounded-lg" alt=""/>

                <div className="ml-8 flex flex-col justify-around flex-1">
                    <p className="font-medium">{book.author}</p>
                    <p className="">42 Unit</p>
                    <p className="">{book.wordCount} Words</p>
                    <p className="mb-2 font-bold text-2xl">Free</p>
                </div>

                {book.free === 0 && (
                    <div className="flex justify-end mt-auto space-x-4">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Buy
                        </button>
                    </div>
                )}
            </div>

            <BookUnitList key={book.id} book={book} />
        </div>
    );
}

export default BookInfo;