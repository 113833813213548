import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import {useParams} from "react-router-dom";
import apiConfig from '../../config/config';

const UnitWordsList = () => {
    const {bookId} = useParams();
    const {unitId} = useParams();

    const [isBlurActive, setIsBlurActive] = useState(false);
    const [clickedWords, setClickedWords] = useState(new Set());

    const [isBlurTranslateActive, setIsBlurTranslateActive] = useState(false);
    const [clickedTranslates, setClickedTranslates] = useState(new Set());


    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiConfig.golangApi}/words/` + bookId + '/' + unitId, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                const rows = data || [];

                if (!Array.isArray(rows)) {
                    throw new Error('Fetched data is not an array');
                }

                setWords(rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [bookId, unitId]);

    const handleSwitchChange = () => {
        setIsBlurActive(!isBlurActive);
    };
    const handleTranslateSwitchChange = () => {
        setIsBlurTranslateActive(!isBlurTranslateActive);
    };

    const handleWordClick = (word) => {
        setClickedWords((prev) => {
            const newClickedWords = new Set(prev);
            if (newClickedWords.has(word)) {
                newClickedWords.delete(word);
            } else {
                newClickedWords.add(word);
            }
            return newClickedWords;
        });
    };

    const handleTranslateClick = (word) => {
        setClickedTranslates((prev) => {
            const newClickedWords = new Set(prev);
            if (newClickedWords.has(word)) {
                newClickedWords.delete(word);
            } else {
                newClickedWords.add(word);
            }
            return newClickedWords;
        });
    };

    if (loading) {
        return <main className="flex-1 p-4">
            <LoadingSpinner/>
        </main>;
    }
    return (
        <div className="table-auto overflow-hidden rounded-lg shadow-lg">
            {/*<Breadcrumb/>*/}

            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs uppercase bg-gray-100 dark:bg-gray-200 text-black">
                <tr className="justify-between w-96  rounded-s-lg">
                    <th scope="col" className="items-center px-6 py-3">
                        Word
                        <label className="inline-flex items-center cursor-pointer pl-3">
                            <input type="checkbox" value="" checked={isBlurActive} onChange={handleSwitchChange}
                                   className="sr-only peer"/>
                            <div
                                className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </th>
                    <th scope="col" className="items-center px-6 py-3">
                        Translate
                        <label className="inline-flex items-center cursor-pointer pl-3">
                            <input type="checkbox" value="" checked={isBlurTranslateActive}
                                   onChange={handleTranslateSwitchChange} className="sr-only peer"/>
                            <div
                                className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </th>
                </tr>
                </thead>
                <tbody className="rounded-b-sm">
                {words.map((word) => (
                    <tr className="bg-white dark:bg-gray-100">
                        <td
                            className={
                                isBlurActive && !clickedWords.has(word) ? 'blurred px-6 py-4 font-medium text-black whitespace-nowrap' : 'px-6 py-4 font-medium text-black whitespace-nowrap'
                            }
                            onClick={() => handleWordClick(word)}
                        >
                            {word.word}
                        </td>

                        <td
                            className={
                                isBlurTranslateActive && !clickedTranslates.has(word) ?  "blurred px-6 py-4" : "px-6 py-4"
                            }
                            onClick={() => handleTranslateClick(word)}>
                            {word.translate}
                        </td>
                    </tr>

                ))}
                </tbody>
            </table>
        </div>
    )
}

export default UnitWordsList