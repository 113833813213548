import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'flowbite';
import {GoogleOAuthProvider} from "@react-oauth/google"
import {AuthProvider} from "./components/context/AuthContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId='721052498531-5shvp2vpp06phk905equ8eesmnr2fqaq.apps.googleusercontent.com'>
        <AuthProvider>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </AuthProvider>
    </GoogleOAuthProvider>
);

reportWebVitals();
