import {Link} from "react-router-dom";

const BookItem = ({id, uniqueId, title, image, author, price}) => {
    return <div key={id} className="group relative rounded-lg border border-b-purple-200 border-x-purple-50">

        <div className="aspect-h-1 aspect-w-1 w-auto border border-gray-100 overflow-hidden rounded-lg
        bg-gray-200 group-hover:opacity-95 h-40
        sm:h-48 md:h-56 lg:h-64 xl:h-80">
            <img
                src={image}
                className="h-full w-full object-cover lg:h-auto lg:w-full"
                alt="Not Found"/>
        </div>
        <div className="mt-4 flex justify-between px-2 pb-3">
            <div>
                <h3 className="text-sm text-gray-700">
                    <Link to={`/book-units/${uniqueId}`}>
                        <span aria-hidden="true" className="absolute inset-0"/>
                        {title}
                    </Link>
                </h3>
                <p className="mt-1 text-sm text-gray-500">{author}</p>
            </div>
            <p className="text-sm font-medium text-gray-900">{price !== 0 ? `${price} AZN` : "Free"}</p>
        </div>
    </div>
}

export default BookItem